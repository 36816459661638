import { WrapRootElement } from './src/contexts/WrapRootElement';

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  if (
    location.pathname === '/endometriosis/cost-and-support/' &&
    navigator.userAgent.includes('Mac') &&
    'ontouchend' in document
  ) {
    document.documentElement.classList.remove('ios');
    document.documentElement.classList.add('ios-cost-and-support');
  } else if (
    location.pathname !== '/endometriosis/cost-and-support/' &&
    navigator.userAgent.includes('Mac') &&
    'ontouchend' in document
  ) {
    document.documentElement.classList.remove('ios-cost-and-support');
    document.documentElement.classList.add('ios');
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  setTimeout(async () => {
    if (location.hash !== '' && location.hash !== 'isi') {
      const offset = document.getElementById(location.hash.split('#')[1])
        ?.offsetTop;

      const headerHeight = document
        .querySelector('header')
        ?.getBoundingClientRect().height;

      const isCookie = await isCookieBanner();

      const isMac = navigator.userAgent.includes('Mac');

      const touchSupport = 'ontouchend' in document;

      if (isCookie && isMac && touchSupport) {
        const cookieBannerHeight = document
          .getElementById('cookie-section')
          ?.getBoundingClientRect().height;

        // console.log(offset! - headerHeight!);

        // console.log(offset, headerHeight, cookieBannerHeight);

        window.scrollTo({
          top: offset! - headerHeight!,
          behavior: 'auto'
        });
      } else {
        if (location.hash !== '#isi') {
          window.scrollTo({ top: offset! - headerHeight!, behavior: 'auto' });
        }
      }
    }
  }, 100);
};

function isCookieBanner() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (document.getElementById('cookie-section')) {
        resolve(true);
      } else {
        resolve(false);
      }
    }, 1);
  });
}

export const wrapRootElement = WrapRootElement;
