const getAllCookies = (): { [key: string]: string } => {
  if (typeof document !== 'undefined') {
    return document.cookie.split('; ').reduce((dictionary, cookie) => {
      const [key, value] = cookie.split('=');
      return { ...dictionary, [key]: value };
    }, {});
  }
  return {};
};

const getSpecificCookie = (name: string) => {
  return getAllCookies()[name];
};

export default getSpecificCookie;
