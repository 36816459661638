/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// For Gatsby, there is a special way to handle context and apply the app provider to the project.
// You simply need to use the app provider in gatsby-browser.js and gatsby-ssr.js, and it will work
// For the entire app

import React from 'react';
import AppProvider from './state';
import { GatsbyBrowser } from 'gatsby';
import DDGStateContextProvider from './DoctorDiscussionGuideContext';
import ExitSurvey, {
  ExitSurveyProps
} from '../components/ExitSurvey/ExitSurvey';

export const WrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  // eslint-disable-next-line react/prop-types
  element
}) => {
  let exitType: ExitSurveyProps['type'] = 'draft';
  if (window) {
    const hostName = window.location.hostname.split('.');
    if (
      hostName.length === 3 &&
      hostName[0] === 'www' &&
      hostName[1] === 'myfembree' &&
      hostName[2] === 'com'
    ) {
      exitType = 'live';
    }
  }
  return (
    <AppProvider>
      <>
        <DDGStateContextProvider>{element}</DDGStateContextProvider>
        {/* <ExitSurvey type={exitType} /> */}
      </>
    </AppProvider>
  );
};
