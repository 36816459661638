import { ButtonProps } from '../Button/Button';
import { DDGProps, DDGQuestion } from './DoctorDiscussionGuideTypes';

const ariaLabel = '';
const color: ButtonProps['color'] = 'cta-fuchsia';
const extraClasses = '';
const href: ButtonProps['href'] = '#';
const target: ButtonProps['target'] = '_self';
// const nsa: (ButtonProps & { id: number, extraClasses: string })[] = [
//   { label: 'NEVER', ariaLabel, color, target, href },
//   { label: 'SOMETIMES', ariaLabel, color, target, href },
//   { label: 'ALWAYS', ariaLabel, color, target, href }
// ];
// const yn: (ButtonProps & { id: number, extraClasses: string })[] = [
//   { label: 'YES', ariaLabel, color, target, href },
//   { label: 'NO', ariaLabel, color, target, href }
// ];
const ynm: (ButtonProps & { id: number; extraClasses: string })[] = [
  { id: 0, label: 'YES', ariaLabel, color, target, href, extraClasses },
  { id: 1, label: 'NO', ariaLabel, color, target, href, extraClasses },
  { id: 2, label: 'MAYBE', ariaLabel, color, target, href, extraClasses }
];
const did: (ButtonProps & { id: number; extraClasses: string })[] = [
  {
    id: 0,
    label: 'DURING MY PERIOD',
    ariaLabel,
    color,
    target,
    href,
    extraClasses
  },
  {
    id: 1,
    label: 'IN BETWEEN PERIODS',
    ariaLabel,
    color,
    target,
    href,
    extraClasses
  },
  { id: 2, label: 'DURING SEX', ariaLabel, color, target, href, extraClasses }
];
const npu: (ButtonProps & { id: number; extraClasses: string })[] = [
  {
    id: 0,
    label: 'NOT THAT BAD',
    ariaLabel,
    color,
    target,
    href,
    extraClasses
  },
  {
    id: 1,
    label: 'PRETTY INTENSE',
    ariaLabel,
    color,
    target,
    href,
    extraClasses
  },
  { id: 2, label: 'UNBEARABLE', ariaLabel, color, target, href, extraClasses }
];
const nsv: (ButtonProps & { id: number; extraClasses: string })[] = [
  { id: 0, label: 'NOT AT ALL', ariaLabel, color, target, href, extraClasses },
  { id: 1, label: 'SOMEWHAT', ariaLabel, color, target, href, extraClasses },
  { id: 2, label: 'VERY MUCH SO', ariaLabel, color, target, href, extraClasses }
];

const nsv1: (ButtonProps & { id: number; extraClasses: string })[] = [
  { id: 0, label: 'LIGHT', ariaLabel, color, target, href, extraClasses },
  { id: 1, label: 'MODERATE', ariaLabel, color, target, href, extraClasses },
  { id: 2, label: 'SEVERE', ariaLabel, color, target, href, extraClasses }
];

const w13: (ButtonProps & { id: number; extraClasses: string })[] = [
  {
    id: 0,
    label: 'WITHIN THE LAST YEAR',
    ariaLabel,
    color,
    target,
    href,
    extraClasses
  },
  {
    id: 1,
    label: '1-3 YEARS AGO',
    ariaLabel,
    color,
    target,
    href,
    extraClasses
  },
  { id: 2, label: '3+ YEARS AGO', ariaLabel, color, target, href, extraClasses }
];
const nso: (ButtonProps & { id: number; extraClasses: string })[] = [
  { id: 0, label: 'NEVER', ariaLabel, color, target, href, extraClasses },
  { id: 1, label: 'SOMETIMES', ariaLabel, color, target, href, extraClasses },
  { id: 2, label: 'OFTEN', ariaLabel, color, target, href, extraClasses }
];
const ynu: (ButtonProps & { id: number; extraClasses: string })[] = [
  { id: 0, label: 'YES', ariaLabel, color, target, href, extraClasses },
  { id: 1, label: 'NO', ariaLabel, color, target, href, extraClasses },
  { id: 2, label: 'UNSURE', ariaLabel, color, target, href, extraClasses }
];
export const DDGQuestionsEM: DDGQuestion[] = [
  {
    question: 'I experience endometriosis pain',
    answers: did,
    multiselect: true
  },
  {
    question: 'When I have endo pain, it feels',
    answers: npu,
    multiselect: false
  },
  {
    question:
      'I have trouble with daily activities like walking, attending social events, exercising, or doing household chores because of my endo pain',
    answers: nsv,
    multiselect: false,
    extraClasses: 'text-md md:text-md4'
  },
  {
    question: 'The pain I experience during sex is',
    answers: nsv1,
    multiselect: false
  },
  {
    question: 'I was diagnosed with endo',
    answers: w13,
    multiselect: false
  },
  {
    question:
      'I use over-the-counter or prescription painkillers for my endo pain',
    answers: nso,
    multiselect: false
  },
  {
    question: 'I feel like I&apos;m not getting relief from my endo pain',
    answers: ynu,
    multiselect: false
  },
  {
    question: 'I would be interested in taking a once-daily pill for endo pain',
    answers: ynm,
    multiselect: false
  },
  {
    question: 'Thank you for your responses!',
    answers: ynm,
    multiselect: false
  }
];

export const DDGEM: DDGProps = {
  filename: 'Endo Conversation Guide.pdf',
  bgColor: 'fuchsia-010',
  color: 'purple-100',
  type: 'EM',
  questions: DDGQuestionsEM
};
