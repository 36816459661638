import React, { Component } from 'react';
import { DDGQuestionsUF } from '../components/DoctorDiscussionGuide/DoctorDiscussionGuideUF';
import {
  DDGContextReducerType,
  DDGContextStateType,
  DDGStateType
} from '../components/DoctorDiscussionGuide/DoctorDiscussionGuideTypes';
import { DDGQuestionsEM } from '../components/DoctorDiscussionGuide/DoctorDiscussionGuideEM';

export let initialDDGQuestionsUF: DDGStateType = {};
export let initialDDGQuestionsEM: DDGStateType = {};

DDGQuestionsUF.forEach((question, questionIndex) => {
  question.answers.forEach((answer) => {
    initialDDGQuestionsUF = {
      ...initialDDGQuestionsUF,
      [questionIndex]: {
        ...initialDDGQuestionsUF[questionIndex],
        [answer.id]: !!initialDDGQuestionsUF?.[questionIndex]?.[answer.id]
      }
    };
  });
});

DDGQuestionsEM.forEach((question, questionIndex) => {
  question.answers.forEach((answer) => {
    initialDDGQuestionsEM = {
      ...initialDDGQuestionsEM,
      [questionIndex]: {
        ...initialDDGQuestionsEM[questionIndex],
        [answer.id]: !!initialDDGQuestionsEM?.[questionIndex]?.[answer.id]
      }
    };
  });
});

export const initialDDGState: DDGContextStateType = {
  UF: initialDDGQuestionsUF,
  EM: initialDDGQuestionsEM,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  update: ({}: DDGContextReducerType) => {},
  restart: () => {}
};

export const DDGStateContext = React.createContext(initialDDGState);

class DDGStateContextProvider extends Component<
  { children: JSX.Element },
  DDGContextStateType
> {
  constructor(props: { children: JSX.Element }) {
    super(props);

    this.state = {
      UF: initialDDGQuestionsUF,
      EM: initialDDGQuestionsEM,
      update: ({
        type,
        questionIndex,
        answerId,
        multiselect
      }: DDGContextReducerType) => {
        switch (type) {
          case 'UF':
            this.setState({
              [type]: {
                ...this.state[type],
                [questionIndex]: {
                  ...this.state[type][questionIndex],
                  ...Object.entries(this.state[type][questionIndex]).reduce<{
                    [index: number]: boolean;
                  }>((accumulator, [currentIndex, currentValue]) => {
                    if (multiselect) {
                      accumulator[Number(currentIndex)] = currentValue;
                    } else {
                      accumulator[Number(currentIndex)] = false;
                    }
                    return accumulator;
                  }, {}),
                  [answerId]: !this.state[type][questionIndex][answerId]
                }
              }
            });
            break;
          case 'EM':
            this.setState({
              [type]: {
                ...this.state[type],
                [questionIndex]: {
                  ...this.state[type][questionIndex],
                  ...Object.entries(this.state[type][questionIndex]).reduce<{
                    [index: number]: boolean;
                  }>((accumulator, [currentIndex, currentValue]) => {
                    if (multiselect) {
                      accumulator[Number(currentIndex)] = currentValue;
                    } else {
                      accumulator[Number(currentIndex)] = false;
                    }
                    return accumulator;
                  }, {}),
                  [answerId]: !this.state[type][questionIndex][answerId]
                }
              }
            });
            break;
        }
      },
      restart: (type: string) => {
        const emState = JSON.parse(JSON.stringify(this.state[type]));

        for (const key in emState) {
          const emAnswer = emState[key];

          for (const key2 in emAnswer) {
            emState[key][key2] = false;
          }
        }

        this.setState({ [type]: emState });
      }
    };
  }

  render() {
    // AppContext.Provider is a built-in function for context, it is important that
    // this.props.children is used and to note that this.state is passed in.

    // The class AppProvider will then wrap the outermost component(s) in the app,
    // In our case at the moment, App.jsx
    return (
      <DDGStateContext.Provider value={this.state}>
        {this.props.children}
      </DDGStateContext.Provider>
    );
  }
}

export default DDGStateContextProvider;
