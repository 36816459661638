module.exports = [{
      plugin: require('../plugins/facebook-pixel-local/gatsby-browser.js'),
      options: {"plugins":[],"fbqCalls":["\"dataProcessingOptions\", [\"LDU\"], 0, 0","\"init\", \"449449579873038\"","\"track\", \"PageView\""]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KXM9CZH","includeInDevelopment":true,"routeChangeEventName":"gatsbyRouteChange","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
